import React, {createContext, useContext, useReducer} from 'react';

export const AppStateContext = createContext();

export const AppStateProvider = ({reducer, initialState, children}) =>(
  <AppStateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppStateContext.Provider>
);

export const useAppState = () => useContext(AppStateContext);

export const appInitialState = {
  navDrawerOpened: false,
  bookingFilters: [],
  bookingSearchTerm: ''
};

export const appReducer = (state, action) => {
  switch (action.type) {
    case 'toggleNavDrawer':
      return {
        ...state,
        navDrawerOpened: action.payload
      };

    case 'REMOVE_BOOKING_FILTER':
      return {
        ...state,
        bookingFilters: state.bookingFilters.filter(bookingFilter => bookingFilter !== action.payload)
      };

    case 'ADD_BOOKING_FILTER':
      return {
        ...state,
        bookingFilters: [...state.bookingFilters, action.payload]
      };

    case 'RESET_BOOKING_FILTERS':
      return {
        ...state,
        bookingFilters: appInitialState.bookingFilters
      };

    case 'MODIFY_BOOKING_SEARCH_TERM':
      return {
        ...state,
        bookingSearchTerm: action.payload
      };

    default:
      return state
  }
};
