import React, {useState, useEffect} from 'react'

const useCurrentUser = (firebase) => {
  const [authUser, setAuthUser] = useState('init');

  useEffect(() => {
    const unlisten = firebase.auth.onAuthStateChanged(
      authUser => {
        authUser && authUser.email.indexOf('@onshoreguide.com') !== -1
          ? setAuthUser(authUser)
          : setAuthUser(null);
      },
    );
    return () => unlisten()
  });

  return [
    authUser
  ]
};

export default useCurrentUser;
