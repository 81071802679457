import React, { useEffect, Suspense } from 'react';
import './App.scss';

import {withFirebase} from "./firebase";
import useCurrentUser from "./hooks/firebase/useCurrentUser";

const loadDashboard = () => import('./pages/Dashboard/Dashboard');
const LoginPage = React.lazy(() => import('./pages/Login/Login'));
const DashboardPage = React.lazy(loadDashboard);

const App = ({ firebase }) => {

  const [currentUser] = useCurrentUser(firebase);

  useEffect(() => {
    loadDashboard();
  }, []);

  if (currentUser === 'init') return <div/>;

  return (
    <div className="app-container">
      <Suspense fallback={<div/>}>
        { currentUser ? <DashboardPage/> : <LoginPage/> }
      </Suspense>

    </div>
  )
};

export default withFirebase(App);
