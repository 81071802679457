import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let firebaseApp = null;

class Firebase {
  constructor() {
    if (!app.apps.length)
      firebaseApp = app.initializeApp(firebaseConfig);

    this.auth = firebaseApp.auth();
    this.firestore = firebaseApp.firestore();
    this.googleProvider = firebaseApp.auth.GoogleAuthProvider;

    this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
  }

  updateBookingData = async (bookingId, bookingData) => {
    return this.firestore.collection('og_users').doc(bookingData.userId)
      .get()
      .then((doc) => {
        if (!doc.exists)
          return Promise.reject('User does not exist');

        let userData = doc.data();

        if (!userData.bookings || userData.bookings.length === 0)
          return Promise.reject('User does not have any bookings.');

        let bookingIdxToEdit = -1;

        userData.bookings.forEach((b, i) => {
          if (b.firebaseUid === bookingId)
            bookingIdxToEdit = i;
        });

        if (bookingIdxToEdit === -1)
          return Promise.reject('This booking does not belong to this user');

        userData.bookings[bookingIdxToEdit] = {...userData.bookings[bookingIdxToEdit], ...bookingData}

        return Promise.all([
          this.firestore.collection('og_users').doc(bookingData.userId).update(userData),
          this.firestore.collection('og_bookings').doc(bookingId).update(bookingData)
        ])
      })
  }
}

export default Firebase;
