import React from 'react';
import App from './App';

import Firebase, { FirebaseContext } from './firebase';
import {appInitialState, appReducer, AppStateProvider} from "./AppState";

export default function Root() {

  return (
    <AppStateProvider initialState={appInitialState} reducer={appReducer}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App/>
      </FirebaseContext.Provider>
    </AppStateProvider>
  );
}
